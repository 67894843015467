
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductsList",
  data() {
    return {
      listOfEventsData: this.listOfEvents,
    };
  },
  props: {
    widgetClasses: String,
    listOfEvents: Array,
  },
  watch: {
    listOfEvents(newVal) {
      this.listOfEventsData = newVal;
    },
  },
  emits: ["reOrder"],
  components: {},
  methods: {
    getFormatedDate(date) {
      //return day and month only
      return new Date(date).toLocaleDateString("pt-PT", {
        day: "2-digit",
        month: "2-digit",
      });
    },
    order() {
      this.listOfEventsData?.reverse();
    },
  },

});
