<template>
  <!--begin::Tables Widget 13-->
  <div class="card row pb-0" :style="widgetClasses" style="border-radius: 0.5px">
    <!--begin::Header-->
    <CustomTableTopFilter
        style="padding-right: 0 !important;"
        :filterStatus="filterStatus"
        :pageProp="pageProp"
        :tableName="tableName"
        :pages="pages"
        :showFilters="showFilters"
        @paginatorHandler="paginatorHandler"
        @updateStatus="updateStatus"
        @queryForOccurences="queryForOccurences"
    ></CustomTableTopFilter>

    <!--end::Header-->

    <hr class="m-0">

    <!--begin::Body-->
    <div class="card-body mb-5 pt-3 pb-0  px-0">
      <!--begin::Table container-->
      <div
          class="overflow-auto col"
          style="overflow-x: scroll !important; max-width: 100%"
      >
        <!--begin::Table-->
        <table class="table table-row-gray-500 gy-3 gs-5 mb-0">
          <!--begin::Table head-->
          <thead>
          <tr class="fw-bolder text-muted">
            <th class="text-dark fs-7 fw-bolder white-text">Documento</th>
            <th class="text-dark fs-7 fw-bolder white-text">V/Encomenda</th>
            <th class="text-dark fs-7 fw-bolder white-text">Emissão</th>
            <th
                v-if="showStatus"
                class="text-center text-dark fs-7 fw-bolder white-text"
                style="min-width: 150px;"
            >
              Estado
            </th>
            <th class="text-end"></th>
          </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
          <template v-for="(item, index) in list" :key="index">
            <tr
                :style="rowColor(item.id)"
                class="border-bot"
                style="vertical-align: middle"
            >
              <td class="text-dark fw-bolder fs-7">
                {{ item.strNumero }}
              </td>
              <td class="fs-7">
                {{ item.strNumRequisicao }}
              </td>
              <td class="fs-7">
                {{ getFormatedDate(item.dtmData) }}
              </td>

              <td v-if="showStatus" class="text-center">
                  <span
                      :class="color(item.CA_EstadoPortal)"
                      :style="orderStatusColors(item.CA_EstadoPortal)"
                      class="badge"
                  >{{ getStatus(item.CA_EstadoPortal)  }}</span
                  >
              </td>

              <td class="text-end" style="min-width: 100px">
                <a
                    v-if="showDownloadBtn && item.invoicePdf"
                    @click="downloadPdf"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                    style="background-color: white"
                    id="btn-pdf-download"
                    :download="item.documentName + item.documentNumber"
                    :href="item.invoicePdf"
                    title="Download pdf document"
                >
                    <span class="svg-icon svg-icon-3" v-if="showDownloadBtn">
                      <inline-svg src="media/icons/duotune/files/fil009.svg"/>
                    </span>
                </a>

                <button
                    @click="toggleShowRowDetail($event, item.id)"
                    class="btn btn-sm btn-icon btn-main-table btn-active-light-primary mx-1 my-1"
                >
                    <span class="svg-icon svg-icon-3 svg-icon-dark">
                      <inline-svg class="svg-icon-transition" src="media/icons/duotune/arrows/arr072.svg"/>
                    </span>
                </button>
              </td>
            </tr>
            <tr v-if="showRow(item.id)">
              <td colspan="7">
                <table
                    class="
                      table table-row-dashed table-row-gray-500
                      gy-3
                      gs-5
                      mb-0
                    "
                >
                  <thead class="border-solid-sub-table">
                  <tr class="fw-bold fs-8 text-gray-800">
                    <th scope="col">Referência</th>
                    <th scope="col">Designação</th>
                    <th scope="col">Qtd.</th>
                    <th v-if="showPendingQtt" scope="col">
                      Qtd. satisfeita
                    </th>
                  </tr>
                  </thead>
                  <tbody
                      v-for="(product, ind) in item.Mov_Encomenda_Lin"
                      :key="ind"
                      class="border-dotted-sub-table text-muted"
                  >
                  <tr
                      class=""
                      v-if="
                          product.strCodArtigo && product.strCodArtigo.trim().length > 0
                        "
                  >
                    <td scope="row">{{ product.strCodArtigo }}</td>
                    <td>{{ product.strDescArtigo }}</td>
                    <td>{{ product.fltQuantidade }}</td>
                    <td v-if="showPendingQtt">{{ product.fltQuantidadeSatisf }}</td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 13-->
</template>

<script lang="js">
import {defineComponent} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import {boolean} from "yup/lib/locale";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableOrdersOccurencesDashboard",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    Dropdown2,
    CustomTableTopFilter,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    filterStatusEti: Array,
    pages: Number,
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showPendingQtt: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences", "changeTable"],
  methods: {

    downloadpdf() {
    },

    showRow(rowId) {
      return this.rowsToShow.includes(rowId);
    },


    /*
    * ----------------DATA MANIPULATION----------------
    */

    toggleShowRowDetail(e, rowId) {
      const index = this.rowsToShow.indexOf(rowId);
      const iconElement = e.target.closest('svg') || e.target.querySelector('svg');
      const button = e.target.closest('button');
      if (index > -1) {
        this.rowsToShow.splice(index, 1);
        iconElement.style.transform = 'rotate(0deg)';
        button.classList.remove("active");
        button.blur();
        return;
      } else {
        this.rowsToShow.push(rowId);
        iconElement.style.transform = 'rotate(180deg)';
        button.classList.add("active");
        return;
      }
    },

    /*
    * ------------------EMITS------------------
    */

    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },

    /*
    * -------------------UTILS--------------------
    */

    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    getStatus(statusCode) {
      //filter status on statuslist and return the status name
      const status = this.filterStatusEti.find(
          (status) => status.strCodigo == statusCode
      );

      if (status) {
        return status.strDescricao;
      }
      return statusCode;
    },

    /*
    * -------------------STYLES-------------------
     */
    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Processamento" ? "success" : "primary";
      return str + color;
    },
    rowColor(id) {
      const index = this.list.findIndex(
          (item) => item.id == id
      );
      if (index % 2 == 0) {
        return "background-color: #DDF3FF;";
      } else {
        return "background-color: rgba(255, 255, 255, 0.07);";
      }
    },

    orderStatusColors(status) {
      switch (status.toUpperCase()) {
        case "ACE":
          return "background-color: #dfdddd; color: black !important; min-width:150px; border: 1px solid #181c32;";
        case "CONCLUIDO":
          return "background-color: #D8FFE7; color: black !important; min-width:150px; border: 1px solid #2CC268;";
        case "REJEI":
        case "NACE":
          return "background-color: #EFCBCB; color: black !important; min-width:150px; border: 1px solid #F14D75;";
        case "PENDENTE":
          return "background-color: #FEF3D8; color: black !important; min-width:150px; border: 1px solid #FFB01A;";
        case "EM APROVAÇÃO":
          return "background-color: #d8e5fe; color: black !important; min-width:150px; border: 1px solid #216cfc;";
        default:
          return "background-color: #e4ebf0; color: #181c32 !important; min-width:150px;";
      }
    },
  },

});
</script>

<style scoped>
.fixTableHead {
  overflow-y: auto;
}

.fixTableHead thead th {
  position: sticky;
  top: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.border-bot {
  border-bottom: 2px solid;
  border-bottom-color: white !important;
}

.border-solid-sub-table {
  border-bottom: 1px solid;
  border-bottom-color: black !important;
}

.border-dotted-sub-table {
  border-bottom: 1px dotted;
  border-bottom-color: grey !important;
}

.border-dotted-sub-table:last-child {
  border-bottom: none !important;
}


.btn-integrated {
  margin-right: 20px;
  background-color: #c8e8ef;
  color: #29b3d2;
  width: 10%;
  min-width: 150px !important;
  font-size: 15px;
}

.btn-integrated:hover {
  background-color: #b4e3ee;
}

.btn-not-integrated {
  background-color: #29b3d2;
  color: white;
  width: 10%;
  min-width: 150px !important;
  font-size: 15px;
}

.btn-not-integrated:hover {
  background-color: #23a0bb;
}
</style>
