
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TopCardInfo",
  components: {},
  props: {
    tittle: String,
    tittleIcon: String,
    values: Array,
    gradientStyle: String,
    info: Object,
    backgroundPositionX: String,
    backgroundPositionY: String,
  },
  computed: {
    bg_img() {
        return (
        "background-image: url('media/" +this.info?.background +
        "'); bottom:0px; top:0px; right:0px; left:0px; width: 100%; overflow: hidden; background-size: 100px;" +
        "height: 100%; background-repeat: no-repeat; background-position-x: " + 
        this.info?.backgroundPositionX + "; background-position-y: " + this.info?.backgroundPositionY + ";"
      );
    },
  },
  setup(props) {
    return {};
  },
});
