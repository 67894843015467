import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xxl-stretch mb-5 mb-xl-8" }
const _hoisted_2 = {
  class: "row",
  style: {"min-height":"45px"}
}
const _hoisted_3 = { class: "col align-self-center pe-0" }
const _hoisted_4 = { class: "text-black fw-bolder fs-7 m-2" }
const _hoisted_5 = { class: "col align-self-center text-end ps-0" }
const _hoisted_6 = { class: "fs-1 m-2 svg-icon svg-icon-1 svg-icon-dark" }
const _hoisted_7 = {
  class: "d-flex flex-column h-100 justify-content-evenly align-items-start mx-2",
  style: {"overflow":"auto","min-height":"110px"}
}
const _hoisted_8 = { class: "symbol symbol-20px symbol-md-20px" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "col-10 d-flex" }
const _hoisted_11 = { class: "d-flex flex-grow-1 align-self-center ps-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.gradientStyle),
      class: "rounded-top"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tittle), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen053.svg" })
          ])
        ])
      ])
    ], 4),
    _createElementVNode("div", {
      class: "card-body",
      style: _normalizeStyle([_ctx.bg_img, {"padding":"0px !important","min-height":"110px"}])
    }, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (elem) => {
          return (_openBlock(), _createElementBlock("div", {
            key: elem.id,
            class: "d-flex flex-row flex-nowrap",
            style: {"box-sizing":"border-box"}
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                src: 'media/' + elem.image
              }, null, 8, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(elem.texto), 1)
            ])
          ]))
        }), 128))
      ])
    ], 4)
  ]))
}